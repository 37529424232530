import commonEnNS from "assets/locales/en/common.json";
import commonViNS from "assets/locales/vi/common.json";
import { Environments } from "data/env/env";
import "i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { getEnv } from "utils/env/env.util";

export const defaultNS = "common" as const;
export const resources = {
  en: { common: commonEnNS },
  vi: { common: commonViNS },
} as const;

export const initializeI18N = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: getEnv() === Environments.local,
      fallbackLng: "en",
      defaultNS,
      ns: ["common"],
      resources,
      interpolation: { escapeValue: false },
      returnNull: false,
    });
};

export const customI18N = i18n;
