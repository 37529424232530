export interface FirestorePathParam {
  collectionName: string;
  docId: string;
}

export enum SalonFirestoreDefinition {
  CollectionName = "salonList",
  MetadataSubCollectionName = "metadata",
  SubscriptionSubCollectionName = "stripeSubscriptionList",
  PaymentMethodSubCollectionName = "stripePaymentMethodList",
  InvoiceSubCollectionName = "stripeInvoiceList",
}

export enum GlobalSettingsFirestoreDefinition {
  CollectionName = "globalSettings",
  DocId = "globalSettings",
}
