import { SalonRole } from "data/admin/app-custom-claims";
import { SalonTier } from "models/payment/plan.dto";
import { UserAuthState } from "./auth-state";

export interface BaseRouteAuthGuard {
  type: "loggedOut" | "loggedIn" | "loggedInWithSalon";
}

export interface RouteAuthGuardLoggedOut extends BaseRouteAuthGuard {
  type: "loggedOut";
}

export interface RouteAuthGuardLoggedIn extends BaseRouteAuthGuard {
  type: "loggedIn";
}

export interface RouteAuthGuardLoggedInWithSalon extends BaseRouteAuthGuard {
  type: "loggedInWithSalon";
  salonRoleList: SalonRole[];
  needActiveMembership: boolean;
}

export type RouteAuthGuard =
  | RouteAuthGuardLoggedOut
  | RouteAuthGuardLoggedIn
  | RouteAuthGuardLoggedInWithSalon;

export const getAuthGuardLoggedOut = (): RouteAuthGuardLoggedOut => ({
  type: "loggedOut",
});

export const getAuthGuardLoggedIn = (): RouteAuthGuardLoggedIn => ({
  type: "loggedIn",
});

export const getAuthGuardLoggedInWithSalon = (
  salonRoleList: SalonRole[],
  options?: {
    needActiveMembership?: boolean;
  },
): RouteAuthGuardLoggedInWithSalon => ({
  type: "loggedInWithSalon",
  salonRoleList,
  needActiveMembership: options?.needActiveMembership ?? true,
});

export const checkUserHasPermissionToRoute = (args: {
  userAuthState: UserAuthState;
  userSalonRole: SalonRole | null;
  salonTier: SalonTier | null;
  routeAuthGuardList: readonly RouteAuthGuard[];
}): boolean => {
  const {
    userAuthState,
    userSalonRole,
    salonTier,
    routeAuthGuardList: routeAuthStateList,
  } = args;

  return routeAuthStateList.some((routeAuthState) =>
    checkUserHasPermissionToRouteGuard({
      userAuthState,
      userSalonRole,
      salonTier,
      routeAuthGuard: routeAuthState,
    }),
  );
};

const checkUserHasPermissionToRouteGuard = (args: {
  userAuthState: UserAuthState;
  userSalonRole: SalonRole | null;
  salonTier: SalonTier | null;
  routeAuthGuard: RouteAuthGuard;
}): boolean => {
  const { userAuthState, routeAuthGuard, salonTier, userSalonRole } = args;

  if (
    routeAuthGuard.type === "loggedOut" &&
    userAuthState.type === "loggedOut"
  ) {
    return true;
  }

  if (routeAuthGuard.type === "loggedIn" && userAuthState.type === "loggedIn") {
    return true;
  }

  if (
    routeAuthGuard.type === "loggedInWithSalon" &&
    userAuthState.type === "loggedIn"
  ) {
    return (
      !!userSalonRole &&
      routeAuthGuard.salonRoleList.includes(userSalonRole) &&
      (!routeAuthGuard.needActiveMembership || salonTier != null)
    );
  }

  return false;
};
