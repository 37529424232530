import { queryClient } from "data/queries/client";
import { OmitStrict } from "ducduchy-react-components/dist/utils/types";
import { useGetSalonInfoQuery } from "hooks/queries/salon/useGetSalonInfoQuery";
import { AppointmentDto } from "models/appointment/appointment.dto";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { AppointmentServices as AppointmentService } from "services/apis/appointment/appointment.service";
import { AppointmentQueryByTimeFrameDto } from "services/definitions/query.definition";
import { APIErrorMessage } from "services/models/error";
import { useStoreState } from "stores";
import { getMillisecondsFromMinutes } from "utils/ui/common";
import { serviceToQueryFn } from "../util";
import { appointmentQueryKeys } from "./query-key";

export type GetAppointmentListByTimeFrameQueryParams = OmitStrict<
  AppointmentQueryByTimeFrameDto,
  "staffId"
>;

export const useGetAppointmentListByTimeFrameQuery = (
  queryParams?: GetAppointmentListByTimeFrameQueryParams,
  options?: OmitStrict<
    UseQueryOptions<AppointmentDto[], any>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<AppointmentDto[]> => {
  const { data: salonInfo, isLoading: isFetchingSalonInfo } =
    useGetSalonInfoQuery();

  const salonId = useStoreState((state) => state.salon.salonId);
  const salonRole = useStoreState((state) => state.salon.salonId);
  const staffId = useStoreState((state) => state.salon.salonStaffId);

  return useQuery<AppointmentDto[], APIErrorMessage>(
    appointmentQueryKeys.appointmentListByTimeFrame(salonId!, queryParams),
    async () => {
      if (!queryParams) {
        return [];
      }

      return serviceToQueryFn(AppointmentService.getAppointmentListByTimeFrame)(
        salonId!,
        {
          fromStartTimeIso: queryParams.fromStartTimeIso,
          toStartTimeIso: queryParams.toStartTimeIso,
          staffId: salonRole === "employee" && staffId ? staffId : null,
        },
      );
    },
    {
      refetchOnWindowFocus: false,
      ...options,
      staleTime: getMillisecondsFromMinutes(10),
      enabled:
        (options?.enabled == null || options.enabled) &&
        !!salonId &&
        !isFetchingSalonInfo &&
        !!salonInfo,
    },
  );
};

export const invalidateGetAppointmentListByTimeFrameQuery = (
  salonId: string,
  queryParams?: GetAppointmentListByTimeFrameQueryParams,
) =>
  queryClient.invalidateQueries(
    appointmentQueryKeys.appointmentListByTimeFrame(salonId, queryParams),
  );

export const updateAppointmentInQueryCache = (
  salonId: string,
  newAppointment: AppointmentDto,
  type: "delete" | "create" | "modify",
) => {
  const baseQueryKey = appointmentQueryKeys.appointmentListByTimeFrame(salonId);

  const allAppointmentKeys = queryClient.getQueryCache().findAll(baseQueryKey);
  allAppointmentKeys.forEach(({ queryKey }) => {
    const curList = queryClient.getQueryData<AppointmentDto[]>(queryKey) ?? [];

    let newList: AppointmentDto[];
    switch (type) {
      case "delete":
        newList = curList.filter(
          (appointment) => appointment.id !== newAppointment.id,
        );
        break;
      case "create":
        newList = [...curList, newAppointment];
        break;
      case "modify":
        newList = curList.map((appointment) =>
          appointment.id === newAppointment.id ? newAppointment : appointment,
        );
        break;
    }

    queryClient.setQueryData(queryKey, newList);
  });
};
