import { getEnv } from "utils/env/env.util";

const resourceLocationMap = {
  // Also use dev server for testing
  local: "http://localhost:5001/apheleia-saas-platform--dev/us-west1/",
  dev: "https://us-west1-apheleia-saas-platform--dev.cloudfunctions.net/",
  prod: "https://us-west1-apheleia-saas-platform.cloudfunctions.net/",
} as const;

export const resourceLocation = resourceLocationMap[getEnv()];

// * General endpoint information
const baseEndpointMap = {
  salon: `${resourceLocation}api/salon/`,
  payment: `${resourceLocation}api/payment/`,
  staffUser: `${resourceLocation}api/staff-user/`,
} as const;

export const salonEndpointMap = {
  getSalon: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "get",
  },
  getSalonOverview: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "get",
  },
  createSalon: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  modifySalonBasicInfo: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "patch",
  },
  deleteSalon: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "delete",
  },
} as const satisfies EndpointMap;

export const serviceEndpointMap = {
  getServiceList: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  getService: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "get",
  },

  createService: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  modifyService: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "patch",
  },

  toggleArchiveStatus: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "patch",
  },
} as const satisfies EndpointMap;

export const customerEndpointMap = {
  getCustomerList: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  createCustomer: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  modifyCustomer: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "patch",
  },
  deleteCustomer: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "delete",
  },
  getCustomerExistByPhoneNumber: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "get",
  },
} as const satisfies EndpointMap;

export const staffEndpointMap = {
  getStaffList: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  createStaff: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  modifyStaff: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "patch",
  },
  deleteStaff: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "delete",
  },
  getInviteSession: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "get",
  },
  createInviteSession: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
} as const satisfies EndpointMap;

export const appointmentEndpointMap = {
  getAppoinmentListByTimeFrame: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  getAppointmentDetail: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "get",
  },
  createAppointment: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  modifyAppointment: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "patch",
  },
  deleteAppointment: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "delete",
  },
  createWalkInAppointment: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
} as const satisfies EndpointMap;

export const feedbackEndpointMap = {
  createFeedback: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
  getFeedbackList: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
} as const satisfies EndpointMap;

export const tagEndpointMap = {
  getTagList: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "get",
  },
  createTag: {
    endpoint: `${baseEndpointMap.salon}`,
    method: "post",
  },
} as const satisfies EndpointMap;

export const paymentEndpointMap = {
  getPlanList: {
    endpoint: `${baseEndpointMap.payment}plans/`,
    method: "get",
  },
  getSmsPackList: {
    endpoint: `${baseEndpointMap.payment}sms-packs/`,
    method: "get",
  },
  createCheckoutSession: {
    endpoint: `${baseEndpointMap.payment}checkout-session/`,
    method: "post",
  },
  createBillingPortal: {
    endpoint: `${baseEndpointMap.payment}billing-portal/`,
    method: "post",
  },
} as const satisfies EndpointMap;

export const staffUserEndpointMap = {
  signUp: {
    endpoint: `${baseEndpointMap.staffUser}sign-up/`,
    method: "post",
  },
  getStaffByInviteToken: {
    endpoint: `${baseEndpointMap.staffUser}get-by-invite-token/`,
    method: "get",
  },
  getUserInviteSessions: {
    endpoint: `${baseEndpointMap.staffUser}get-invite-sessions/`,
    method: "get",
  },
  acceptInvite: {
    endpoint: `${baseEndpointMap.staffUser}accept-invitation/`,
    method: "post",
  },
  declineInvite: {
    endpoint: `${baseEndpointMap.staffUser}decline-invitation/`,
    method: "post",
  },
  getSalons: {
    endpoint: `${baseEndpointMap.staffUser}get-salons/`,
    method: "get",
  },
} as const satisfies EndpointMap;

interface EndpointMap {
  [key: string]: {
    endpoint: string;
    method: "get" | "post" | "patch" | "delete";
  };
}
