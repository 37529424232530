import { action, Action } from "easy-peasy";
import { ReactNode } from "react";

export interface AppHeaderModel {
  title: string | null;
  content: ReactNode | null;

  setTitle: Action<AppHeaderModel, string | null>;
  setContent: Action<AppHeaderModel, ReactNode | null>;

  resetStore: Action<AppHeaderModel>;
}

export const appHeader: AppHeaderModel = {
  title: null,
  content: null,

  setTitle: action((state, title) => {
    state.title = title;
  }),
  setContent: action((state, content) => {
    state.content = content;
  }),

  resetStore: action((state) => {
    state.title = null;
    state.content = null;
  }),
};
