import { useStoreState } from "stores";
import "./AppHeader.scss";
import { useGetSalonInfoQuery } from "hooks/queries/salon/useGetSalonInfoQuery";

export const AppHeader = () => {
  const title = useStoreState((state) => state.appHeader.title);
  const content = useStoreState((state) => state.appHeader.content);

  const { data: salonData } = useGetSalonInfoQuery();

  if (!title && !content) {
    return null;
  }

  return (
    <div className="app-header flex justify-between">
      {title && <h1 className="app-header__title">{title}</h1>}
      {salonData && <h2 className="app-header--show-salon-name">{salonData.name} </h2>}
      {content}
    </div>
  );
};
