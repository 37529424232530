import {
  getAuthGuardLoggedIn,
  getAuthGuardLoggedInWithSalon,
  getAuthGuardLoggedOut,
  RouteAuthGuard,
} from "models/auth/auth-guard";
import { lazy } from "react";

const MySalonsPage = lazy(() => import("pages/internals/MySalonsPage"));
const AccountSettingPage = lazy(
  () => import("pages/internals/AccountSettingPage"),
);
const MembershipPage = lazy(() => import("pages/internals/MembershipPage"));

const SalonInfoPage = lazy(() => import("pages/internals/SalonInfoPage"));
const AppointmentInfoPage = lazy(
  () => import("pages/internals/AppointmentInfoPage"),
);
const CheckInPage = lazy(() => import("pages/internals/CheckInPage"));
const FeedbackInfoPage = lazy(() => import("pages/internals/FeedbackInfoPage"));

const ServiceInfoPage = lazy(() => import("pages/internals/ServiceInfoPage"));
const StaffInfoPage = lazy(() => import("pages/internals/StaffInfoPage"));
const CustomerInfoPage = lazy(() => import("pages/internals/CustomerInfoPage"));

const StaffInviteEntryPage = lazy(
  () => import("pages/internals/StaffInviteEntryPage"),
);
const UnderConstructionPage = lazy(() => import("pages/UnderConstructionPage"));

const SelfCheckInPage = lazy(() => import("pages/internals/SelfCheckInPage"));

const LoginPage = lazy(() => import("pages/externals/LoginPage"));
const RegisterPage = lazy(() => import("pages/externals/RegisterPage"));
const AppointmentFeedbackPage = lazy(
  () => import("pages/externals/AppointmentFeedbackPage"),
);

export const routeBasePath = {
  // External
  login: "/login",
  accountSetting: "/settings",
  register: "/register",
  appointmentFeedback: "/feedback",

  // Internal
  salonInfo: "/salon-info",
  scheduling: "/scheduling",
  checkIn: "/checkIn",
  feedback: "/salon-feedback",

  services: "/services",
  customers: "/customers",
  staffs: "/staffs",

  mySalons: "/my-salons",
  membership: "/membership",
  staffInvite: "/staff-invite",

  selfCheckIn: "/self-check-in",
} as const;

interface RouteData {
  authGuardList: readonly RouteAuthGuard[];
  exact?: boolean;

  Component: React.ComponentType;
}

export const miscRoute = {
  uc: { Component: UnderConstructionPage },
} as const;

export const baseRouteDataMap = {
  // External
  login: {
    authGuardList: [getAuthGuardLoggedOut()],
    Component: LoginPage,
  },
  register: {
    authGuardList: [getAuthGuardLoggedOut()],
    Component: RegisterPage,
  },
  appointmentFeedback: {
    authGuardList: [getAuthGuardLoggedOut(), getAuthGuardLoggedIn()],
    Component: AppointmentFeedbackPage,
  },

  // Internal
  salonInfo: {
    authGuardList: [
      getAuthGuardLoggedInWithSalon(["owner", "manager", "receptionist"], {
        needActiveMembership: false,
      }),
    ],
    Component: SalonInfoPage,
  },
  accountSetting: {
    authGuardList: [
      getAuthGuardLoggedInWithSalon(["owner", "manager", "receptionist"], {
        needActiveMembership: false,
      }),
    ],
    Component: AccountSettingPage,
  },
  scheduling: {
    authGuardList: [
      getAuthGuardLoggedInWithSalon(["owner", "manager", "receptionist"]),
    ],
    Component: AppointmentInfoPage,
  },
  checkIn: {
    authGuardList: [
      getAuthGuardLoggedInWithSalon(["owner", "manager", "receptionist"]),
    ],
    Component: CheckInPage,
  },
  feedback: {
    authGuardList: [getAuthGuardLoggedInWithSalon(["owner", "manager"])],
    Component: FeedbackInfoPage,
  },
  services: {
    authGuardList: [getAuthGuardLoggedInWithSalon(["owner", "manager"])],
    Component: ServiceInfoPage,
  },
  customers: {
    authGuardList: [
      getAuthGuardLoggedInWithSalon(["owner", "manager", "receptionist"]),
    ],
    Component: CustomerInfoPage,
  },
  staffs: {
    authGuardList: [getAuthGuardLoggedInWithSalon(["owner", "manager"])],
    Component: StaffInfoPage,
  },
  mySalons: {
    authGuardList: [getAuthGuardLoggedIn()],
    Component: MySalonsPage,
  },
  membership: {
    authGuardList: [
      getAuthGuardLoggedInWithSalon(["owner"], { needActiveMembership: false }),
    ],
    Component: MembershipPage,
  },
  staffInvite: {
    authGuardList: [getAuthGuardLoggedOut(), getAuthGuardLoggedIn()],
    Component: StaffInviteEntryPage,
  },
  selfCheckIn: {
    authGuardList: [
      getAuthGuardLoggedInWithSalon(["owner", "manager", "receptionist"]),
    ],
    Component: SelfCheckInPage,
  },
} as const satisfies Record<keyof typeof routeBasePath, RouteData>;
