import { getAuth } from "firebase/auth";
import { ServiceError } from "services/models/error";
import { FirebaseAuthError, FirebaseErrorCode } from "services/models/firebase";

export function handleFirebaseError<T extends FirebaseErrorCode>(
  err: any,
): ServiceError<T> {
  // TODO: handle more of this
  const firebaseErr = err as FirebaseAuthError;

  return {
    // @ts-ignore
    message: firebaseErr.code,
    detailMessage: firebaseErr.message,
  };
}

const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

const MAX_RETRY = 10;
const RETRY_INTERVAL = 500;
/**
 * @param forceRefresh
 * Force refresh regardless of token expiration.
 */
export const getFirebaseUserToken = async (
  forceRefresh?: boolean,
): Promise<string> => {
  let token = await getFirebaseUser()?.getIdToken(forceRefresh);

  let counter = 0;
  for (; counter < MAX_RETRY && !token; counter++) {
    // try again first
    await timeout(RETRY_INTERVAL);
    token = await getFirebaseUser()?.getIdToken();
  }

  if (counter > 0) {
    console.warn(`Account load after ${counter} retries`);
  }
  if (!token) {
    // TODO: Handle toast
    // toastCustomMessage(
    //   "Fail to load your account, please refresh or check your network",
    //   "error",
    // );
    throw new Error(`Fail to load account after ${counter} retries`);
  }

  return token;
};

export const getFirebaseUser = () => getAuth().currentUser;

export const getUserPhoneNumber = () => getFirebaseUser()?.phoneNumber;

export const getUserEmail = () => getFirebaseUser()?.email;
