import axios, { AxiosError, Method } from "axios";
import { APIErrorMessage, ServiceError } from "services/models/error";
import { ServiceResult } from "services/models/service";
import { extractBackendError } from "services/util/error";
import { getFirebaseUserToken } from "services/utils/firebase";

type HeaderContentType = "multipart/form-data";

export type CustomHeader = Partial<{
  "Content-Type": HeaderContentType;
}>;

interface SendRequestProps<
  T,
  ErrorMessageType extends APIErrorMessage = APIErrorMessage,
> {
  endpointInfo: {
    endpoint: string;
    method: Method;
  };
  data?: T;
  params?: object;
  extraPath?: string[];
  useTokenInHeaders?: boolean;
  customHeader?: CustomHeader;

  transformError?: (
    err: any,
  ) => Promise<ServiceError<ErrorMessageType>> | ServiceError<ErrorMessageType>;
}

export async function sendRequest<
  ResT = any,
  ReqT = any,
  ErrorMessageType extends APIErrorMessage = APIErrorMessage,
>({
  endpointInfo,
  data,
  useTokenInHeaders = true,
  customHeader,
  params,
  extraPath,
  transformError = extractBackendError,
}: SendRequestProps<ReqT, ErrorMessageType>): Promise<
  ServiceResult<ResT, ErrorMessageType>
> {
  let result: ServiceResult<ResT, ErrorMessageType> = {};

  try {
    const baseURL =
      endpointInfo.endpoint + (extraPath ? `${extraPath?.join("/")}` : "");
    const headers: Record<string, any> = { ...customHeader };

    if (useTokenInHeaders) {
      const token = await getFirebaseUserToken();
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await axios({
      baseURL,
      params,
      headers,
      method: endpointInfo.method,
      data,
    });

    result.result = response.data as ResT;
  } catch (error) {
    const transformedError =
      (await transformError?.(
        (error as AxiosError).isAxiosError
          ? (error as AxiosError)?.response?.data
          : error ?? error,
      )) || (error as ServiceError<any>);

    result.error = transformedError;

    // TODO: remove this once we have a comprehensive error handling system
    console.error(JSON.stringify(result.error));
  }

  return result;
}
