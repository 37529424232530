import { action, Action } from "easy-peasy";

export interface SidebarModel {
  isSidebarBlank: boolean;

  setIsSidebarBlank: Action<SidebarModel, boolean>;

  resetStore: Action<SidebarModel>;
}

export const sidebar: SidebarModel = {
  isSidebarBlank: false,

  setIsSidebarBlank: action((state, isSidebarBlank) => {
    state.isSidebarBlank = isSidebarBlank;
  }),

  resetStore: action((state) => {
    state.isSidebarBlank = false;
  }),
};
