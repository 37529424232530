import { AppCustomClaims } from "data/admin/app-custom-claims";
import { queryClient } from "data/queries/client";
import {
  action,
  Action,
  computed,
  Computed,
  persist,
  thunk,
  Thunk,
} from "easy-peasy";
import { User } from "firebase/auth";
import { UserAuthState } from "models/auth/auth-state";
import { AuthService } from "services/apis/auth/auth.service";
import { SalonService } from "services/apis/salon/salon.service";
import { FirebaseAuthErrorCode } from "services/models/firebase";
import { StoreModel } from "stores";
import { LoadingModel, loadingPlugin } from "stores/plugins/loading.plugin";
import { executeAsyncWithLoading } from "utils/services/async.util";

export enum AuthLoadingType {
  LoggingIn,
  LoggingOut,

  CreatingAccount,
  ResettingPassword,
  Reauthenticate,
}

export interface AuthModel extends LoadingModel<AuthLoadingType> {
  userCredential: User | null;
  customClaims: AppCustomClaims | null;
  inviteToken: string | null;

  isLoggedIn: Computed<AuthModel, boolean>;
  userAuthState: Computed<AuthModel, UserAuthState>;

  setUserCredential: Action<AuthModel, User | null>;
  setCustomClaims: Action<AuthModel, AppCustomClaims | null>;
  setInviteToken: Action<AuthModel, string | null>;

  setUpAuthListener: Thunk<AuthModel, never, any, StoreModel>;
  resetStore: Thunk<AuthModel, never, any, StoreModel>;

  login: Thunk<
    AuthModel,
    { email: string; password: string },
    any,
    any,
    Promise<FirebaseAuthErrorCode | null>
  >;
  signUp: Thunk<
    AuthModel,
    { email: string; password: string; inviteToken?: string },
    any,
    any,
    Promise<FirebaseAuthErrorCode | null>
  >;
  logout: Thunk<AuthModel>;
  refreshUser: Thunk<AuthModel, never, any, any, Promise<void>>;
  resetPassword: Thunk<
    AuthModel,
    string,
    any,
    any,
    Promise<FirebaseAuthErrorCode | null>
  >;

  reauthenticateCurrentUserWithPassword: Thunk<
    AuthModel,
    string,
    any,
    any,
    Promise<FirebaseAuthErrorCode | null>
  >;
}

export const auth: AuthModel = persist(
  {
    userCredential: null,
    customClaims: null,
    inviteToken: null,
    isLoggedIn: computed(
      [(state) => state.userCredential],
      (userCredential) => userCredential != null,
    ),
    userAuthState: computed([(state) => state.customClaims], (customClaims) => {
      let userAuthState: UserAuthState;

      if (!customClaims) {
        userAuthState = { type: "loggedOut" };
      } else {
        userAuthState = {
          type: "loggedIn",
        };
      }

      return userAuthState;
    }),

    setUserCredential: action((state, userCredential) => {
      state.userCredential = userCredential;
    }),
    setCustomClaims: action((state, customClaims) => {
      state.customClaims = customClaims;
    }),
    setInviteToken: action((state, inviteToken) => {
      state.inviteToken = inviteToken;
    }),

    login: thunk(async (actions, { email, password }) => {
      const { error } = await executeAsyncWithLoading({
        funcToExecute: () => AuthService.login(email, password),
        setIsLoading: (isLoading) =>
          actions.setIsLoading({
            loadingType: AuthLoadingType.LoggingIn,
            isLoading,
          }),
      });

      return error?.message ?? null;
    }),
    signUp: thunk(async (actions, { email, password, inviteToken }) => {
      const { error } = await executeAsyncWithLoading({
        funcToExecute: () => AuthService.signUp(email, password, inviteToken),
        setIsLoading: (isLoading) =>
          actions.setIsLoading({
            loadingType: AuthLoadingType.CreatingAccount,
            isLoading,
          }),
      });

      if (error?.message) {
        return error.message;
      }

      return await actions.login({ email, password });
    }),
    logout: thunk(() => {
      // Remove everything from the backend
      queryClient.removeQueries();

      AuthService.logout();
    }),
    refreshUser: thunk(async (actions) => {
      // We can't use store's userCredential because its functions isn't stored
      const user = SalonService.getCurrentUser();

      if (!user) {
        return;
      }

      const claims = (await user.getIdTokenResult(true))?.claims;

      claims && actions.setCustomClaims(claims as unknown as AppCustomClaims);
    }),

    // * Thunks
    setUpAuthListener: thunk((actions, _) => {
      SalonService.onAuthStateChange(async (user) => {
        actions.setUserCredential(user);

        if (!user) {
          actions.resetStore();
        }

        const claims = (await user?.getIdTokenResult())?.claims;
        claims && actions.setCustomClaims(claims as unknown as AppCustomClaims);
      });
    }),

    resetPassword: thunk(async (actions, email) => {
      const { error } = await executeAsyncWithLoading({
        funcToExecute: () => AuthService.resetPassword(email),
        setIsLoading: (isLoading) =>
          actions.setIsLoading({
            loadingType: AuthLoadingType.ResettingPassword,
            isLoading,
          }),
      });

      return error?.message ?? null;
    }),

    reauthenticateCurrentUserWithPassword: thunk(async (actions, password) => {
      const { error } = await executeAsyncWithLoading({
        funcToExecute: () =>
          AuthService.reauthenticateCurrentUserWithPassword(password),
        setIsLoading: (isLoading) =>
          actions.setIsLoading({
            loadingType: AuthLoadingType.Reauthenticate,
            isLoading,
          }),
      });

      return error?.message ?? null;
    }),

    resetStore: thunk((actions, _1, { getStoreActions }) => {
      actions.setCustomClaims(null);
      actions.setUserCredential(null);

      const storeAction = getStoreActions();
      storeAction.account.resetStore();
      storeAction.theme.resetStore();
      storeAction.salon.resetStore();
    }),

    ...loadingPlugin(),
  },
  { allow: ["userCredential", "customClaims"], storage: "localStorage" },
);
