import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import "dayjs/locale/vi";
import isBetween from "dayjs/plugin/isBetween";
import isToday from "dayjs/plugin/isToday";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import { initFirebase } from "loaders/firebase.loader";
import { initializeI18N } from "loaders/i18n.loader";
import { initializeIconList } from "loaders/icon.loader";
import { useEffect } from "react";

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const initAOS = () => {
  AOS.init();
};

export const useInit = () => {
  useEffect(() => {
    initAOS();
    initializeI18N();
    initializeIconList();

    initFirebase();
  }, []);
};
