import { localeDateFormatMap } from "data/i18n/data.data";
import dayjs from "dayjs";
import { useStoreState } from "stores";

/**
 * Format a date based on the locale. Use `useDateFormatter` to get the formatter without the hook.
 */
export const useFormatDate = (date: string, format?: string) => {
  const locale = useStoreState((state) => state.account.locale);

  const dayjsDate = dayjs(date);
  return format
    ? dayjsDate.format(format)
    : dayjsDate.format(localeDateFormatMap[locale]);
};
