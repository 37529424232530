import { APIErrorMessage, ServiceError } from "services/models/error";

export const isAPIError = (error: unknown): error is ServiceError => {
  if (typeof error !== "object" || !error) {
    return false;
  }

  return (
    error.hasOwnProperty("status") &&
    error.hasOwnProperty("message") &&
    error.hasOwnProperty("detailMessage")
  );
};

export const extractBackendError = <
  E extends APIErrorMessage = APIErrorMessage,
>(
  err: any,
): ServiceError<E> => {
  if (!isAPIError(err)) {
    return {
      message: "server/internal" as E,
      detailMessage: JSON.stringify(err),
    };
  }

  return { message: err.message as E, detailMessage: err.detailMessage };
};
