import {
  AppointmentDetailDto,
  AppointmentDto,
} from "models/appointment/appointment.dto";
import {
  AppointmentWriteDto,
  ModifyAppointmentStatusDto,
} from "models/appointment/appointment-write.dto";
import { appointmentEndpointMap } from "services/definitions/endpoint.definition";
import {
  AppointmentQueryByServiceDto,
  AppointmentQueryByTimeFrameDto,
} from "services/definitions/query.definition";
import {
  APIErrorMessage,
  AppointmentErrorMessage,
} from "services/models/error";
import { ServiceResult } from "services/models/service";
import { sendRequest } from "services/utils/api";
import { WalkInAppointmentWriteDto } from "models/appointment/walk-in-appointment-write.dto";

export class AppointmentServices {
  static async getAppointmentListByTimeFrame(
    salonId: string,
    queryParams: AppointmentQueryByTimeFrameDto,
  ): Promise<ServiceResult<AppointmentDto[], AppointmentErrorMessage>> {
    const { result, error } = await sendRequest<
      AppointmentDto[],
      any,
      AppointmentErrorMessage
    >({
      endpointInfo: appointmentEndpointMap.getAppoinmentListByTimeFrame,
      extraPath: [salonId, "appointment", "query-by-timeframe"],
      data: { ...queryParams },
    });

    return { result: result ?? [], error };
  }

  static async getAppointmentListByService(
    salonId: string,
    serviceId: string,
  ): Promise<ServiceResult<AppointmentDto[], AppointmentErrorMessage>> {
    const { result, error } = await sendRequest<
      AppointmentDto[],
      AppointmentQueryByServiceDto,
      AppointmentErrorMessage
    >({
      endpointInfo: appointmentEndpointMap.getAppoinmentListByTimeFrame,
      extraPath: [salonId, "appointment", "query-by-service"],
      data: {
        serviceId,
      },
    });

    return { result: result ?? [], error };
  }

  static async getAppointmentDetail(
    salonId: string,
    appointmentId: string,
  ): Promise<ServiceResult<AppointmentDetailDto, AppointmentErrorMessage>> {
    const { result, error } = await sendRequest<
      AppointmentDetailDto,
      any,
      AppointmentErrorMessage
    >({
      endpointInfo: appointmentEndpointMap.getAppointmentDetail,
      extraPath: [salonId, "appointment", appointmentId],
    });

    return { result, error };
  }

  static async getAppointmentByGuest(
    salonId: string,
    appointmentId: string,
  ): Promise<ServiceResult<AppointmentDetailDto, AppointmentErrorMessage>> {
    return await sendRequest<
      AppointmentDetailDto,
      any,
      AppointmentErrorMessage
    >({
      endpointInfo: appointmentEndpointMap.getAppointmentDetail,
      extraPath: [salonId, "appointment", appointmentId, "by-guest"],
      useTokenInHeaders: false,
    });
  }

  static async createAppointment(args: {
    salonId: string;
    writeDto: AppointmentWriteDto;
  }): Promise<ServiceResult<AppointmentDto, APIErrorMessage>> {
    const { salonId, writeDto } = args;

    return sendRequest<AppointmentDto, AppointmentWriteDto, APIErrorMessage>({
      endpointInfo: appointmentEndpointMap.createAppointment,
      extraPath: [salonId, "appointment"],
      data: writeDto,
    });
  }

  static async deleteAppointment(
    salonId: string,
    appointmentId: string,
  ): Promise<ServiceResult<void, AppointmentErrorMessage>> {
    return sendRequest<void, never, AppointmentErrorMessage>({
      endpointInfo: appointmentEndpointMap.deleteAppointment,
      extraPath: [salonId, "appointment", appointmentId],
    });
  }

  static async modifyAppointment(args: {
    salonId: string;
    appointmentId: string;
    writeDto: AppointmentWriteDto;
  }): Promise<ServiceResult<AppointmentDto, APIErrorMessage>> {
    const { salonId, appointmentId, writeDto } = args;

    return sendRequest<AppointmentDto, AppointmentWriteDto, APIErrorMessage>({
      endpointInfo: appointmentEndpointMap.modifyAppointment,
      extraPath: [salonId, "appointment", appointmentId],
      data: writeDto,
    });
  }

  static async modifyAppointmentStatus(args: {
    salonId: string;
    appointmentId: string;
    data: ModifyAppointmentStatusDto;
  }): Promise<ServiceResult<void, AppointmentErrorMessage>> {
    const { salonId, appointmentId, data } = args;

    return sendRequest<
      void,
      ModifyAppointmentStatusDto,
      AppointmentErrorMessage
    >({
      endpointInfo: appointmentEndpointMap.modifyAppointment,
      extraPath: [salonId, "appointment", appointmentId, "status"],
      data,
    });
  }

  static async createWalkInAppointment(args: {
    salonId: string;
    writeDto: WalkInAppointmentWriteDto;
  }): Promise<ServiceResult<AppointmentDto, APIErrorMessage>> {
    const { salonId, writeDto } = args;

    return sendRequest<
      AppointmentDto,
      WalkInAppointmentWriteDto,
      APIErrorMessage
    >({
      endpointInfo: appointmentEndpointMap.createAppointment,
      extraPath: [salonId, "appointment-walk-in"],
      data: writeDto,
    });
  }
}
