interface ExecuteAsyncWithLoadingProp<Result> {
  setIsLoading: (isLoading: boolean) => void;
  funcToExecute: (...arg: any) => Promise<Result> | Result;
  onComplete?: (result: Result) => void;
}

export async function executeAsyncWithLoading<Result>({
  funcToExecute,
  setIsLoading,
  onComplete,
}: ExecuteAsyncWithLoadingProp<Result>): Promise<Result> {
  setIsLoading(true);
  const result = await funcToExecute();
  onComplete?.(result);
  setIsLoading(false);

  return result;
}
