import { AppCustomClaims, SalonRole } from "data/admin/app-custom-claims";
import { action, Action, computed, Computed, persist } from "easy-peasy";
import { SalonTier } from "models/payment/plan.dto";
import { StoreModel } from "stores";

const getSalonClaim = (
  salonId: string | null,
  customClaims: AppCustomClaims | null,
) => {
  if (!salonId || !customClaims) {
    return null;
  }

  return (
    customClaims.salonClaimList.find((claim) => claim.salonId === salonId) ??
    null
  );
};

export interface SalonModel {
  salonId: string | null;
  salonTier: SalonTier | null;
  salonRole: Computed<SalonModel, SalonRole | null, StoreModel>;
  salonStaffId: Computed<SalonModel, string | null, StoreModel>;

  setSalonId: Action<SalonModel, string | null>;
  setSalonTier: Action<SalonModel, SalonTier | null>;
  resetStore: Action<SalonModel>;
}

export const salon: SalonModel = persist(
  {
    salonId: null,
    salonTier: null,
    salonRole: computed(
      [
        (state) => state.salonId,
        (_, storeState) => storeState.auth.customClaims,
      ],
      (salonId, customClaims) =>
        getSalonClaim(salonId, customClaims)?.salonRole ?? null,
    ),
    salonStaffId: computed(
      [
        (state) => state.salonId,
        (_, storeState) => storeState.auth.customClaims,
      ],
      (salonId, customClaims) =>
        getSalonClaim(salonId, customClaims)?.staffId ?? null,
    ),

    setSalonId: action((state, payload) => {
      state.salonId = payload;
    }),
    setSalonTier: action((state, payload) => {
      state.salonTier = payload;
    }),

    resetStore: action((state) => {
      state.salonId = null;
    }),
  },
  { allow: ["salonId", "salonTier"], storage: "localStorage" },
);
