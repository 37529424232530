import { useEffect } from "react";
import { useStoreActions } from "stores";

export const useI18nManager = () => {
  const initLocale = useStoreActions((actions) => actions.account.initLocale);

  useEffect(() => {
    initLocale();
  }, [initLocale]);

  return null;
};
