import { SalonFirestoreDefinition } from "data/firestore/firestore.data";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { AppointmentDto } from "models/appointment/appointment.dto";
import { useEffect, useRef } from "react";
import { useStoreState } from "stores";
import { updateAppointmentInQueryCache } from "./useGetAppointmentListByTimeFrameQuery";

// Cache to prevent duplicate snapshot mount
const isSnapshotMountOnceDumbCache: Record<string, boolean> = {};

export const useWatchAppointmentMetadata = (salonId: string | null) => {
  const salonRole = useStoreState((state) => state.salon.salonRole);

  // On first mount, we want to fetch the whole data of the day instead
  // This is to prevent the race condition where the metadata is updated before
  // the appointment list is fetched
  const didSkipFirstRunRef = useRef(false);

  useEffect(() => {
    // We don't want to fetch the metadata if the user is not a salon staff
    if (!salonRole || !salonId || isSnapshotMountOnceDumbCache[salonId]) {
      return;
    }

    isSnapshotMountOnceDumbCache[salonId] = true;
    const unsubscribe = onSnapshot(
      doc(
        getFirestore(),
        SalonFirestoreDefinition.CollectionName,
        salonId,
        SalonFirestoreDefinition.MetadataSubCollectionName,
        APPOINTMENT_REALTIME_METADATA_DOC_ID,
      ),
      (doc) => {
        if (!didSkipFirstRunRef.current) {
          didSkipFirstRunRef.current = true;
          return;
        }

        const data = doc.data() as AppointmentCollectionMetadata;

        if (!data) {
          console.warn("Missing appointment metadata");
          return;
        }

        const { type, appointmentDtoJsonString: appointmentDtoString } = data;
        if (!appointmentDtoString || !type) {
          console.warn("Missing appointment metadata fields");
          return;
        }

        const appointment = JSON.parse(appointmentDtoString) as AppointmentDto;
        updateAppointmentInQueryCache(salonId, appointment, type);
      },
    );

    return () => {
      unsubscribe();
      didSkipFirstRunRef.current = false;
      isSnapshotMountOnceDumbCache[salonId] = false;
    };
  }, [salonId, salonRole]);
};

// TODO: move this out once we have more use case for this
interface AppointmentCollectionMetadata {
  type: "modify" | "create" | "delete";
  appointmentDtoJsonString: string;
  // There are more but we don't need them for now
}

const APPOINTMENT_REALTIME_METADATA_DOC_ID = "appointmentListRealtime";
