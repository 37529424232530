import { SupportLanguage } from "./i18n.data";

export const STANDARD_DATE_FORMAT = "DD/MM/YYYY";

export const localeDateFormatMap: Record<SupportLanguage, string> = {
  // vi: "DD/MM/YYYY",
  vi: "MM/DD/YYYY",
  en: "MM/DD/YYYY",
};

export type DeadlineState = "info" | "success" | "warning" | "danger";

export const deadlineThresholdList: {
  numDate: number;
  state: DeadlineState;
}[] = [
  {
    numDate: 2,
    state: "danger",
  },
  {
    numDate: 5,
    state: "warning",
  },
];
