import { SalonRole } from "data/admin/app-custom-claims";
import { CommonNSTranslationKey } from "data/i18n/i18n.data";
import { baseRouteDataMap, routeBasePath } from "data/navigation/route-list";
import { IconProps } from "ducduchy-react-components";
import { checkUserHasPermissionToRoute } from "models/auth/auth-guard";
import { UserAuthState } from "models/auth/auth-state";
import { SalonTier } from "models/payment/plan.dto";

export interface SidebarGroupInfo {
  title: CommonNSTranslationKey;
}

export const sideBarGroupList = [
  "account--internal",
  "overview--internal",
  "salonDetail--internal",

  "overview--external",
] as const;
export type SidebarGroup = (typeof sideBarGroupList)[number];

export const sidebarGroupInfoMap: Record<SidebarGroup, SidebarGroupInfo> = {
  "account--internal": { title: "navigation.group.account" },
  "overview--internal": { title: "navigation.group.overview" },
  "salonDetail--internal": { title: "navigation.group.salonDetail" },

  "overview--external": { title: "navigation.group.overview" },
};

export interface SidebarItemInfo {
  title: CommonNSTranslationKey;
  icon: IconProps["icon"];
  pathKey: keyof typeof routeBasePath;

  hidden?: boolean;
}

export const shouldShowSidebarItem = (
  sidebarItemInfo: SidebarItemInfo,
  userSalonRole: SalonRole | null,
  salonTier: SalonTier | null,
  userAuthState: UserAuthState,
) => {
  return (
    checkUserHasPermissionToRoute({
      userAuthState,
      userSalonRole,
      salonTier,
      routeAuthGuardList:
        baseRouteDataMap[sidebarItemInfo.pathKey].authGuardList,
    }) && !sidebarItemInfo.hidden
  );
};

// * --- For logged in routes
export const sidebarItemInfoMap: Record<SidebarGroup, SidebarItemInfo[]> = {
  // Internal groups
  "account--internal": [
    {
      title: "navigation.path.mySalons",
      icon: ["fas", "user"],
      pathKey: "mySalons",
    },
    {
      title: "navigation.path.accountSettings",
      icon: ["fas", "user-gear"],
      pathKey: "accountSetting",
    },
  ],
  "overview--internal": [
    {
      title: "navigation.path.salonInfo",
      icon: ["fas", "chart-line"],
      pathKey: "salonInfo",
    },
    {
      title: "navigation.path.scheduling",
      icon: ["fas", "calendar"],
      pathKey: "scheduling",
    },
    {
      title: "navigation.path.checkIn",
      icon: ["fas", "calendar"],
      pathKey: "checkIn",
    },
    {
      title: "navigation.path.feedback",
      icon: ["fas", "thought-bubble"],
      pathKey: "feedback",
    },
    {
      title: "navigation.path.selfCheckIn",
      icon: ["fas", "clipboard-list-check"],
      pathKey: "selfCheckIn",
    },
  ],
  "salonDetail--internal": [
    {
      title: "navigation.path.services",
      icon: ["fas", "bell-concierge"],
      pathKey: "services",
    },
    {
      title: "navigation.path.customers",
      icon: ["fas", "user-group"],
      pathKey: "customers",
    },
    {
      title: "navigation.path.staffs",
      icon: ["fas", "user-gear"],
      pathKey: "staffs",
    },
    {
      title: "navigation.path.membership",
      icon: ["fas", "file-invoice-dollar"],
      pathKey: "membership",
    },
  ],

  // External groups
  "overview--external": [
    {
      title: "navigation.path.login",
      icon: ["fas", "right-to-bracket"],
      pathKey: "login",
    },
    {
      title: "navigation.path.register",
      icon: ["fas", "registered"],
      pathKey: "register",
    },
  ],
};
