import { GetAppointmentListByTimeFrameQueryParams } from "./useGetAppointmentListByTimeFrameQuery";

export const appointmentQueryKeys = {
  appointmentListByTimeFrame: (
    salonId: string,
    queryParams?: GetAppointmentListByTimeFrameQueryParams,
  ) =>
    [
      "appointmentListByTimeFrame",
      salonId,
      ...(queryParams ? [queryParams] : []),
    ] as const,
  appointmentListByService: (salonId: string, serviceId: string) =>
    ["appointmentListByService", salonId, serviceId] as const,
  appointmentDetail: (salonId: string | null, appointmentId: string | null) =>
    ["appointmentDetail", salonId, appointmentId] as const,
  appointmentByGuest: (salonId: string, appointmentId: string) =>
    ["appointmentByGuest", salonId, appointmentId] as const,
} as const;
