import { Environments } from "data/env/env";
import {
  firebaseDevConfig,
  firebaseProdConfig,
} from "data/firebase/firebase.data";
import { getApp, getApps, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getEnv } from "utils/env/env.util";

export const initFirebase = () => {
  if (getApps().length <= 0) {
    const curEnv = getEnv();
    console.info(`Initialized in ${curEnv}`);

    initializeApp(
      curEnv === Environments.prod ? firebaseProdConfig : firebaseDevConfig,
    );

    if (curEnv === Environments.local) {
      const BASE_HOST = "localhost";

      const FIRESTORE_PORT = 8080;
      const AUTH_PORT = 9099;

      connectFirestoreEmulator(getFirestore(), BASE_HOST, FIRESTORE_PORT);
      connectAuthEmulator(getAuth(), `http://${BASE_HOST}:${AUTH_PORT}`, {
        disableWarnings: true,
      });
    }
  } else {
    getApp();
  }
};
