import { createStore, createTypedHooks } from "easy-peasy";
import { AuthModel, auth } from "stores/account/auth.store";
import { SalonModel, salon } from "stores/salon/salon.store";
import { AppHeaderModel, appHeader } from "stores/ui/app-header.store";
import { ThemeModel, theme } from "stores/ui/theme.store";
import { AccountModel, account } from "./account/account.store";
import { SidebarModel, sidebar } from "./ui/sidebar.store";

export interface StoreModel {
  theme: ThemeModel;
  appHeader: AppHeaderModel;
  sidebar: SidebarModel;

  account: AccountModel;
  auth: AuthModel;

  salon: SalonModel;
}

const model: StoreModel = {
  theme,
  appHeader,
  sidebar,

  account,
  auth,

  salon,
};

const { useStoreState, useStoreActions } = createTypedHooks<StoreModel>();

export const store = createStore(model);
export { useStoreActions, useStoreState };
