import { Environments } from "data/env/env";

export const getEnv = (): Environments => {
  switch (process.env.REACT_APP_MY_ENV) {
    case "local":
      return Environments.local;
    case "dev":
      return Environments.dev;
    case "prod":
      return Environments.prod;
    default:
      throw new Error("Invalid environment");
  }
};
